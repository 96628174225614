<template>
  <div id="onlineExam">
    <!-- hiddenDiv :class="{ displayDiv: loaded }" v-if="!confirmed" -->
    <div class="">
      <v-row>
        <v-col cols="12">
          <div class="title">
            <h1>{{ HomeWorkData.classes }}</h1>
          </div>
          <div class="title">
            <h2 class="main-color" v-if="HomeWorkData.subject != ''">
              {{ HomeWorkData.subject }} - {{ HomeWorkData.title }}
            </h2>
            <h2 class="main-color" v-else>
              {{ HomeWorkData.title }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="info-list">
          <ul class="inline">
            <li>
              <strong>{{ $t("Publish Date") }}:</strong>
              {{ HomeWorkData.publish_date }}
            </li>
            <li>
              <strong>{{ $t("Due Date") }}:</strong>
              {{ HomeWorkData.due_date }}
            </li>
            <li v-if="HomeWorkData.year_name != ''">
              <strong>{{ $t("Grade") }}:</strong>
              {{ HomeWorkData.grade }}
            </li>

            <li>
              <strong>{{ $t("Marks") }}:</strong>
              {{ HomeWorkData.mark }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="2" class="icons-div text-right">
          <v-icon
            class="main-color"
            title="Add Section"
            @click="addSection()"
            large
            >add_circle</v-icon
          >
        </v-col>
        <v-col cols="12" md="12" class="hr-div">
          <hr />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-form v-model="valid" ref="form">
            <div
              class="exam-section"
              v-for="(section, sectionIndex) in HomeWorkSections"
              :key="sectionIndex"
            >
              <v-row class="sectionInput">
                <v-col cols="11" md="11">
                  <label for="section head">{{ $t("Section") }}</label>
                  <v-textarea
                    solo
                    label
                    v-model="section.section_title"
                    :rules="requiredRules"
                    prepend-icon="drag_indicator"
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="1" md="1" style="margin-top: 25px">
                  <p>
                    <v-icon
                      class="main-color"
                      title="Duplicate section"
                      @click="duplicateSection(section)"
                      >file_copy</v-icon
                    >
                  </p>
                  <p>
                    <v-icon
                      class="main-color"
                      title="Delete Section"
                      @click.prevent="
                        openConfirmDialog('section', index, sectionIndex)
                      "
                      >delete</v-icon
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-for="(question, index) in section.questions"
                :key="index"
              >
                <v-col cols="12" sm="12" class="questionbody">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <label>{{ $t("Question") }}</label>

                      <!-- <v-text-field
                        solo
                        v-model="question.head"
                        :rules="requiredRules"
                      ></v-text-field> -->
                      <vue-editor
                        v-model="question.head"
                        :rules="requiredRules"
                      ></vue-editor>
                      <p class="red--text" v-if="question.head == ''">
                        {{ $t("This field is required") }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>{{ $t("Question type") }}</label>

                      <v-select
                        :items="types"
                        v-model="question.type"
                        :item-name="types.text"
                        :item-value="types.value"
                        solo
                        @change="
                          checkTypeQuestion(index, sectionIndex, question.type)
                        "
                      ></v-select>
                      <!-- @change="questionType(question.type, sectionIndex, index)" -->
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>{{ $t("Marks") }}</label>
                      <v-text-field v-model="question.Marks"
                       type="number" min="0" 
                       :rules="[
                            required.required,
                            CheckIfMarksExceedExamMark(),
                            numeric.numeric,
                          ]" disabled solo v-if="question.type == 'join'">
                      </v-text-field>
                      <v-text-field
                        v-model="question.Marks"
                        type="number"
                        min="0"
                        :rules="[
                          required.required,
                          CheckIfMarksExceedExamMark(),
                          numeric.numeric,
                        ]"
                        solo v-else
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="question.type == 'essay'"
                    >
                      <label>{{ $t("Min characters") }}</label>
                      <v-text-field
                        v-model="question.min"
                        min="0"
                        type="number"
                        solo
                        :rules="[
                          checkMinLessThanMax(question.min, question.max),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="question.type == 'essay'"
                    >
                      <label>{{ $t("Max characters") }}</label>
                      <v-text-field
                        v-model="question.max"
                        min="0"
                        type="number"
                        solo
                        :rules="[
                          checkMinLessThanMax(question.min, question.max),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="question.type == 'essay'">
                    <v-col cols="12" sm="12">
                      <v-textarea
                        solo
                        label
                        v-model="question.answer"
                        :placeholder="$t('Answer goes here')"
                        rows="2"
                      ></v-textarea>
                      <!-- <label>Answer goes here</label> -->
                      <!-- <vue-editor v-model="question.answer"></vue-editor> -->
                    </v-col>
                  </v-row>
                  <!-- tof -->
                  <v-row v-if="question.type == 'tof'">
                    <v-col cols="12" sm="12">
                      <v-radio-group
                        v-model="question.answer"
                        :rules="requiredRules"
                        row
                      >
                        <v-radio :label="$t('True')" value="True"></v-radio>
                        <v-radio :label="$t('False')" value="False"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <!-- msq -->
                  <v-row v-if="question.type == 'mcq'">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      v-for="(option, optionIndex) in question.options"
                      :key="optionIndex"
                      class="active"
                    >
                      <v-row>
                        <v-col cols="10" sm="10">
                          <!-- <v-text-field
                            v-model="option.value"
                            :label="option.key"
                            solo
                            :rules="[
                              required.required,
                              checkIfOptionDuplicated(
                                question.options,
                                option.value
                              ),
                            ]"
                            >{{ option.value }}</v-text-field
                          > -->
                          <label>{{ option.key }}</label>
                          <vue-editor v-model="option.value"></vue-editor>
                          <p class="red--text" v-if="option.value == ''">
                            {{ $t("This field is required") }}
                          </p>
                        </v-col>
                        <v-col cols="1" sm="1" style="margin-top: 10px">
                          <v-icon
                            @click="
                              ChooseOption(index, sectionIndex, option.value)
                            "
                            :color="
                              option.value == question.answer &&
                              question.answer != ''
                                ? 'green'
                                : '#7297FF'
                            "
                            >check_circle</v-icon
                          >
                        </v-col>
                        <v-col cols="1" md="1" style="margin-top: 10px">
                          <v-icon
                            @click="
                              DeleteOption(index, sectionIndex, optionIndex)
                            "
                            title="Delete Option"
                            class="main-color"
                            >delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- Join -->
                  <v-row v-if="question.type == 'join'">
                    <v-col
                      cols="12"
                      sm="12"
                      v-for="(join, joinIndex) in question.joins"
                      :key="joinIndex"
                      class="active"
                    >
                      <v-row>
                        <v-col cols="3" sm="3">
                          <label>{{ join.join_question_number }}</label>
                          <v-text-field
                            v-model="join.join_question"
                            solo
                            :rules="[
                              validateJoinQuestion(
                                index,
                                sectionIndex,
                                joinIndex
                              ),
                            ]"
                            >{{ join.join_question }}</v-text-field
                          >
                          <!-- <vue-editor v-model="join.join_question">{{
                            join.join_question
                          }}</vue-editor> -->
                        </v-col>
                        <v-col cols="2" sm="2">
                          <label>{{ $t("Correct Answer") }}</label>
                          <v-select
                            :items="join.all_answers_items"
                            v-model="join.correct_answer"
                            solo
                            :rules="[
                              validateCorrectAnswer(
                                index,
                                sectionIndex,
                                joinIndex
                              ),
                            ]"
                            @change="
                              FilterCorrectAnswer(
                                index,
                                sectionIndex,
                                joinIndex
                              )
                            "
                          ></v-select>
                        </v-col>
                        <v-col cols="4" sm="4">
                          <label>{{ join.join_answer_number }}</label>
                          <v-text-field
                            v-model="join.join_answer"
                            solo
                            :rules="[required.required]"
                            >{{ join.join_answer }}</v-text-field
                          >
                        </v-col>
                        <v-col cols="2" sm="2">
                          <label>{{ $t("Mark") }}</label>
                          <v-text-field
                            v-model="join.join_mark"
                            min="0"
                            type="number"
                            solo
                            :rules="[
                              CheckIfJoinMarksExceedExamMark,
                              required.required,
                            ]"
                            @change="CollectQuestionMark(index, sectionIndex)"
                            @keyup="CollectQuestionMark(index, sectionIndex)"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="1" md="1" style="margin-top: 30px">
                          <v-icon
                            @click="
                              DeleteSentence(index, sectionIndex, joinIndex)
                            "
                            title="Delete Sentence"
                            class="main-color"
                            >delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- mcq -->
                  <v-row v-if="question.type == 'mcq'">
                    <v-col cols="6" sm="6" class="addOption">
                      <span
                        class="clickable"
                        @click="AddOption(index, sectionIndex)"
                      >
                        <v-icon class="main-color" title="Add option" large
                          >add</v-icon
                        >
                        <span style="margin-top: 0.5rem">{{
                          $t("Add Option")
                        }}</span>
                      </span>
                    </v-col>
                  </v-row>
                  <!-- join -->
                  <v-row v-if="question.type == 'join'">
                    <v-col cols="6" sm="6" class="addOption">
                      <span
                        class="clickable"
                        @click="AddSentence(index, sectionIndex)"
                      >
                        <v-icon class="main-color" title="Add sentence" large
                          >add</v-icon
                        >
                        <span style="margin-top: 0.5rem">{{
                          $t("Add Sentence")
                        }}</span>
                      </span>
                    </v-col>
                  </v-row>

                  <hr class="hr-bold" />
                  <v-row class="tags">
                    <v-col cols="12" sm="6" md="6">
                      <!-- <vue-tags-input
                        v-model="tag"
                        :tags="question.tags"
                        :autocomplete-items="filteredItems"
                        @tags-changed="(newTags) => (question.tags = newTags)"
                      /> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right">
                      <v-icon
                        class="main-color"
                        title="Duplicate qustion"
                        @click="duplicateQuestion(index, sectionIndex)"
                        >file_copy</v-icon
                      >

                      <v-icon
                        class="main-color"
                        title="Delete question"
                        @click="
                          openConfirmDialog('question', index, sectionIndex)
                        "
                        >delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-row class="divider"></v-row>
              </v-row>
              <v-row class="addQuestion">
                <span class="clickable" @click="addQuestion(sectionIndex)">
                  <v-icon title="Add question" class="main-color" large
                    >add</v-icon
                  >
                  <span style="margin-top: 0.5rem">{{
                    $t("Add question")
                  }}</span>
                </span>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" sm="12">
                <div class="btns text-right">
                  <button
                    class="ui-btn submit"
                    v-if="!homeWorkSaved"
                    @click.prevent="saveHomeWork"
                  >
                    <!-- @click.prevent="" -->
                    {{ $t("Save") }}
                  </button>
                  <button
                    class="ui-btn submit"
                    v-if="homeWorkSaved"
                    @click.prevent="updateHomeWork()"
                  >
                    <!-- @click.prevent="" -->
                    {{ $t("Update") }}
                  </button>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="300"
        class="dialog_confirm"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirmation Message")
          }}</v-card-title>

          <v-card-text>
            {{ $t("Are you sure you want to permanently remove these") }}
            "{{ $t(deletedItem) }}"
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="modal-btn-cancel noBtn2"
              @click="confirmDialog = false"
              >{{ $t("No") }}</v-btn
            >
            <v-btn
              @click="deleteItem(deletedItem)"
              class="modal-btn-save yesBtn2"
              >{{ $t("Yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- <div class="loader text-center" v-if="!loaded">
      <img src="../../assets/Spinner-1s-200px.gif" alt />
    </div> -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <!-- <div v-if="confirmed">
      <v-row>
        <v-col cols="12" md="12">
          <v-alert class="info_alert">
            You Cannot Edit This Exam Because It's confirmed
          </v-alert>
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
// import VueTagsInput from "@johmun/vue-tags-input";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "CreateHomeWork",
  components: {
    // VueTagsInput,
    VueEditor,
  },
  mixins: [validationMixin],
  data() {
    return {
      snack: false,
      snackText: "",
      snackColor: "",
      snackTime: 3000,
      selectedType: "essay",
      classId: "",
      homeworkId: "",
      homeWorkSaved: false,

      HomeWorkData: {
        title: "",
        publish_date: "",
        due_date: "",
        subject: "",
        classes: "",
        mark: "",
        auto_correction: "",
        grade: "",
      },
      loaded: false,
      valid: false,
      deletedItem: "",
      confirmDialog: false,
      selectedQuestionIndex: "",
      selectedSectionIndex: "",
      types: [
        { text: this.$i18n.t("Essay"), value: "essay" },
        { text: this.$i18n.t("JOIN The Sentences"), value: "join" },
        { text: this.$i18n.t("MCQ"), value: "mcq" },
        { text: this.$i18n.t("True or False"), value: "tof" },
      ],

      autocompleteItems: [],
      HomeWorkSections: [
        {
          section_title: "",
          questions: [
            {
              head: "",
              type: "essay",
              Marks: "",
              min: "",
              max: "",

              question: "",
              answer: "",
              options: [],
              joins: [],
            },
          ],
        },
      ],
      alphapaticChars: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      requiredRules: [(v) => !!v || this.$i18n.t("This field is required")],
      required: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
      },
      numeric: {
        numeric: (v) => v >= 0 || "Number should be more or equal to 0",
      },
    };
  },
  methods: {
    getHomeWorkData() {
      axios
        .get(this.getApiUrl + "/homework/show/" + this.homeworkId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.HomeWorkData = response.data.data;
          this.loaded = true;
          this.homeWorkSaved = this.HomeWorkData.saved;

          this.checkAutoCorrection();
          // this.confirmed = this.ExamData.confirmed;

          if (this.homeWorkSaved === true) {
            this.getEditHomeWork();
          }
        });
    },

    getEditHomeWork() {
      axios
        .get(
          this.getApiUrl + "/homework/showAssimentQuestions/" + this.homeworkId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.HomeWorkSections = response.data.data["sections"];
        });
    },
    CheckIfJoinMarksExceedExamMark() {
      let total_mark = 0;
      let homeWork_mark = parseFloat(this.HomeWorkData.total_mark);
      this.HomeWorkSections.forEach((section) => {
        for (let i = 0; i < section.questions.length; i++) {
          if (section.questions[i].type != "join") {
            if (section.questions[i].Marks != "")
              total_mark += parseFloat(section.questions[i].Marks);
          } else {
            section.questions[i].joins.forEach((join) => {
              if (join.join_mark != "")
                total_mark += parseFloat(join.join_mark);
            });
          }
        }
      });
      // console.log(total_mark);
      if (total_mark > homeWork_mark) {
        return "Total questions marks shouldnot exceed exam mark";
      }
      return true;
    },

    CheckJoinMarks(questionIndex, sectionIndex, type) {
      if (type == "join") {
        let joinMark = 0;
        this.HomeWorkSections[sectionIndex]["questions"][
          questionIndex
        ].joins.forEach((join) => {
          if (join.join_mark != "") {
            joinMark += join.join_mark;
          }
        });
        if (
          joinMark >
          this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
            "Marks"
          ]
        ) {
          return "Join total cannot be greater than question total";
        } else {
          return true;
        }
      }
      return true;
    },
    checkMinLessThanMax(min, max) {
      if (min && max) {
        if (parseFloat(min) > parseFloat(max)) {
          return "Min characters should be less than max characters";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    CheckIfMarksExceedExamMark() {
      let total_mark = 0;
      let homeWork_mark = parseFloat(this.HomeWorkData.total_mark);
      this.HomeWorkSections.forEach((section) => {
        section.questions.forEach((question) => {
          total_mark += parseFloat(question.Marks);
        });
      });
      if (total_mark > homeWork_mark) {
        return "Total questions marks shouldnot exceed homeWork mark";
      }
      return true;
    },
    validateCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      if (
        (this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["join_question"] != "" ||
          joinIndex == 0) &&
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["correct_answer"] == ""
      ) {
        return this.$i18n.t("This field is required");
      }
      return true;
    },
    validateJoinQuestion(questionIndex, sectionIndex, joinIndex) {
      if (
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["join_question"] == "" &&
        joinIndex == 0
      ) {
        return this.$i18n.t("This field is required");
      }
      return true;
    },
    FilterCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      let answer = this.HomeWorkSections[sectionIndex]["questions"][
        questionIndex
      ]["joins"][joinIndex]["correct_answer"];
      let empty_all_item_answers;
      empty_all_item_answers = [
        ...this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["all_answers_items"],
      ];
      let correct_answers_array = [];
      let question_numbers_array = [];
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_array.push(join.correct_answer);
        question_numbers_array.push(join.join_answer_number);
        if (join.correct_answer != answer && answer != "") {
          let all_answers_items = [];
          for (let index = 0; index < join.all_answers_items.length; index++) {
            if (join.all_answers_items[index] != answer) {
              all_answers_items.push(join.all_answers_items[index]);
            }
          }

          join.all_answers_items = [];
          join.all_answers_items = [...all_answers_items];
        } else if (answer == "") {
          empty_all_item_answers.forEach((item) => {
            let answer_found = join.all_answers_items.includes(item);
            // console.log(answer_found);
            if (!answer_found) {
              join.all_answers_items.push(item);
            }
          });
        }
      });
      let questions_numbers_not_in_correct_answers = [];
      question_numbers_array.forEach((number) => {
        if (!correct_answers_array.includes(number)) {
          questions_numbers_not_in_correct_answers.push(number);
        }
      });
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        questions_numbers_not_in_correct_answers.forEach((number) => {
          if (!join.all_answers_items.includes(number)) {
            join.all_answers_items.push(number);
          }
        });
      });
    },
    CollectQuestionMark(questionIndex, sectionIndex) {
      let mark = 0;
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        if (join.join_mark != "") mark += parseFloat(join.join_mark);
      });
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "Marks"
      ] = mark;
    },
    checkIfOptionDuplicated(options, value) {
      let error = true;
      let exist = 0;
      options.forEach((option) => {
        if (option.value == value) {
          // console.log(value);
          exist += 1;
        }
      });
      if (exist > 1) {
        return "This answer already exist";
      }
      return error;
    },
    ChooseOption(questionIndex, sectionIndex, optionValue) {
      // console.log(optionValue);
      if (optionValue != "") {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "answer"
        ] = optionValue;
      }
    },
    DeleteSentence(questionIndex, sectionIndex, joinIndex) {
      if (
        "id" in
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]
      ) {
        axios
          .get(
            this.getApiUrl +
              "/homework/deleteOptionJoin/" +
              this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
                "joins"
              ][joinIndex]["id"],
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == true) {
              this.snack = true;
              this.snackText = "error in delete sentence";
              this.snackColor = "red";
            }
          });
      }

      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].splice(joinIndex, 1);
      for (
        let i = 1;
        i <=
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex]["joins"]
          .length;
        i++
      ) {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][i - 1]["join_question_number"] = i;
        if (i - 1 == 0) {
          this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
            "joins"
          ][i - 1]["join_answer_number"] = "a";
        } else {
          this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
            "joins"
          ][i - 1]["join_answer_number"] = this.alphapaticChars[
            this.alphapaticChars.indexOf(
              this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
                "joins"
              ][i - 2]["join_answer_number"]
            ) + 1
          ];
        }
      }
      let all_answers_items = [];
      all_answers_items.push("");
      // let correct_answers_items = [];
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.correct_answer = "";
        all_answers_items.push(join.join_answer_number);
      });
      // this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
      //   "joins"
      // ].forEach(join => {
      //   if (correct_answers_items.includes())
      //     all_answers_items.push(join.join_answer_number);
      // });
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.all_answers_items = [...all_answers_items];
      });
    },
    DeleteOption(questionIndex, sectionIndex, optionIndex) {
      if (
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "answer"
        ] ==
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "options"
        ][optionIndex]["value"]
      ) {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "answer"
        ] = "";
      }
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "options"
      ].splice(optionIndex, 1);
    },
    AddOption(questionIndex, sectionIndex) {
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "options"
      ].push({ key: this.$i18n.t("Option"), value: "" });
    },
    AddSentence(questionIndex, sectionIndex) {
      let joinLenght = this.HomeWorkSections[sectionIndex]["questions"][
        questionIndex
      ]["joins"].length;

      console.log(questionIndex, sectionIndex);
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].push({
        join_question: "",
        join_question_number:
          joinLenght > 0
            ? parseFloat(
                this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
                  "joins"
                ][
                  this.HomeWorkSections[sectionIndex]["questions"][
                    questionIndex
                  ]["joins"].length - 1
                ]["join_question_number"]
              ) + 1
            : 1,
        join_answer: "",
        join_answer_number:
          joinLenght > 0
            ? this.alphapaticChars[
                this.alphapaticChars.indexOf(
                  this.HomeWorkSections[sectionIndex]["questions"][
                    questionIndex
                  ]["joins"][
                    this.HomeWorkSections[sectionIndex]["questions"][
                      questionIndex
                    ]["joins"].length - 1
                  ]["join_answer_number"]
                ) + 1
              ]
            : this.alphapaticChars[0],
        all_answers_items: ["", "a"],
        join_mark: "",
        correct_answer: "",
      });

      let all_answers_items = [];
      all_answers_items.push("");
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        all_answers_items.push(join.join_answer_number);
        // join.all_answers_items.push(new_char);
      });
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.all_answers_items = [...all_answers_items];
      });

      let correct_answers_items = [];
      this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_items.push(join.correct_answer);
      });

      correct_answers_items.forEach((correct) => {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ].forEach((join) => {
          if (join.correct_answer != correct && correct != "") {
            if (join.all_answers_items.includes(correct)) {
              join.all_answers_items.splice(
                join.all_answers_items.indexOf(correct),
                1
              );
            }
          }
        });
      });
    },
    checkTypeQuestion(questionIndex, sectionIndex, questionType) {
      if (questionType == "tof") {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "options"
        ] = [
          { key: "Option1", value: "True" },
          { key: "Option2", value: "False" },
        ];
      } else if (questionType == "mcq") {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "options"
        ] = [{ key: this.$i18n.t("Option"), value: "" }];
      } else if (questionType == "join") {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ] = [
          {
            join_question: "",
            join_question_number: 1,
            join_answer: "",
            join_answer_number: "a",
            all_answers_items: ["", "a"],
            join_mark: "",
            correct_answer: "",
          },
        ];
      } else {
        this.HomeWorkSections[sectionIndex]["questions"][questionIndex][
          "options"
        ] = [];
      }
    },

    addSection() {
      let sectionObj = {
        section_title: "",
        questions: [
          {
            head: "",
            type: "essay",
            Marks: "",
            min: "",
            max: "",
            // tags: [],
            // tag: "",
            question: "",
            answer: "",
            options: [],
            joins: [],
          },
        ],
      };
      this.HomeWorkSections.push(sectionObj);
    },
    addQuestion(sectionIndex) {
      let type = "";
      // console.log(this.HomeWorkSections[sectionIndex].questions.length - 1);
      if (
        sectionIndex >= 0 &&
        this.HomeWorkSections[sectionIndex].questions.length - 1 != -1
      ) {
        this.selectedType = this.HomeWorkSections[sectionIndex].questions[
          this.HomeWorkSections[sectionIndex].questions.length - 1
        ].type;
        type = this.selectedType;
      } else {
        type = "essay";
      }
      let options;
      let joins;
      if (type == "tof") {
        options = [
          { key: "Option1", value: "True" },
          { key: "Option2", value: "False" },
        ];
      } else if (type == "mcq") {
        options = [{ key: this.$i18n.t("Option"), value: "" }];
      } else if (type == "join") {
        joins = [
          {
            join_question: "",
            join_question_number: 1,
            join_answer: "",
            join_answer_number: "a",
            all_answers_items: ["", "a"],
            correct_answer: "",
          },
        ];
      } else {
        options = [];
      }
      let questionObj = {
        head: "",
        type: type,
        Marks: "",
        min: "",
        max: "",
        // tags: [],
        // tag: "",
        question: "",
        answer: "",
        options: options,
        joins: joins,
      };
      this.HomeWorkSections[sectionIndex].questions.push(questionObj);
    },

    duplicateSection(section) {
      // console.log(section);
      let sections = [...this.HomeWorkSections];
      let newSection = section;
      let questions = JSON.parse(JSON.stringify(newSection.questions));
      questions.forEach((q) => {
        q.question_id = "";

        if (q.joins && q.joins.length > 0) {
          q.joins.forEach((j) => {
            j.id = "";
          });
        }
      });
      let sec = {
        section_title: newSection.section_title,
        section_id: "",
        questions: questions,
      };
      sec.questions.question_id = "";
      sections.push(sec);

      this.HomeWorkSections = [...sections];
    },
    duplicateQuestion(questionIndex, sectionIndex) {
      // let questionsArray = this.HomeWorkSections[sectionIndex].questions;
      let newQuestion = Object.assign(
        {},
        this.HomeWorkSections[sectionIndex].questions[questionIndex]
      );

      // newQuestion.joins.forEach(j => {
      //   j.id = "";
      // });
      let joins = JSON.parse(JSON.stringify(newQuestion.joins));
      joins.forEach((j) => {
        j.id = "";
      });
      // console.log(joins);
      let questionObj = {
        question_id: "",
        head: newQuestion.head,
        type: newQuestion.type,
        Marks: newQuestion.Marks,
        min: newQuestion.min,
        max: newQuestion.max,
        // tags: newQuestion.tags,
        // tag: newQuestion.tag,
        question: newQuestion.question,
        answer: newQuestion.answer,
        options: JSON.parse(JSON.stringify(newQuestion.options)),
        joins: JSON.parse(JSON.stringify(joins)),
      };

      this.HomeWorkSections[sectionIndex].questions.push(questionObj);

      // this.HomeWorkSections[sectionIndex].questions = [...questionsArray];
    },

    deleteSection(sectionIndex) {
      let sectionId = this.HomeWorkSections[sectionIndex].section_id;
      if (sectionId) {
        axios
          .get(this.getApiUrl + "/homework/deleteSection/" + sectionId, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.HomeWorkSections.splice(sectionIndex, 1);
            }
          });
      } else {
        this.HomeWorkSections.splice(sectionIndex, 1);
      }
    },
    deleteQuestion(index, sectionIndex) {
      let question_id = this.HomeWorkSections[sectionIndex].questions[index]
        .question_id;

      if (question_id) {
        axios
          .get(this.getApiUrl + "/homework/deleteQuestion/" + question_id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.HomeWorkSections[sectionIndex].questions.splice(index, 1);
            }
          });
      } else {
        this.HomeWorkSections[sectionIndex].questions.splice(index, 1);
      }
    },
    saveHomeWork() {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        let error = false;
        let questionsMarks = 0;
        if (this.HomeWorkSections.length > 0) {
          this.HomeWorkSections.forEach((element) => {
            element["questions"].forEach((question) => {
              /* get the sum of all questions marks */
              questionsMarks += Number(question.Marks);

              if (
                (question.answer == "" || question.answer == null) &&
                question.type != "join"
              ) {
                // console.log(question.type);
                this.snack = true;
                this.snackText = this.$i18n.t("please put all answers first");
                this.snackColor = "red";
                error = true;
              }
            });
          });

          if (questionsMarks != Number(this.HomeWorkData.mark)) {
            this.snack = true;
            this.snackText = this.$i18n.t(
              "Make sure the total of questions marks equal the assignment marks"
            );
            this.snackColor = "red";
            error = true;
          }
        }
        if (error == false) {
          axios
            .post(
              this.getApiUrl + "/homework/saveQuestions/" + this.homeworkId,
              { sections: this.HomeWorkSections },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.$router.push({
                  path: "/homework/",
                });
              }
            });
        }
      }
    },
    updateHomeWork() {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        let error = false;
        let questionsMarks = 0;
        if (this.HomeWorkSections.length > 0) {
          this.HomeWorkSections.forEach((element) => {
            element["questions"].forEach((question) => {
              /* get the total mark of all questions */
              questionsMarks += Number(question.Marks);

              if (
                (question.answer == "" || question.answer == null) &&
                question.type != "join"
              ) {
                this.snack = true;
                this.snackText = this.$i18n.t("please put all answers first");
                this.snackColor = "red";
                error = true;
              }
            });
          });

          if (questionsMarks != Number(this.HomeWorkData.mark)) {
            this.snack = true;
            this.snackText = this.$i18n.t(
              "Make sure the total of questions marks equal the assignment marks"
            );
            this.snackColor = "red";
            error = true;
          }
        }
        if (error == false) {
          axios
            .post(
              this.getApiUrl +
                "/homework/updateAssignmentQuestions/" +
                this.homeworkId,
              { sections: this.HomeWorkSections },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              // console.log(response.data.status.error);
              if (response.data.status.error == false) {
                this.$router.push({
                  path: "/studentshomeworkresults/" + this.homeworkId,
                });
              }
            });
        }
      }
    },

    // confirmExam() {
    //   axios
    //     .get(this.getApiUrl + "/onlineexams/confirmed/" + this.homeworkId, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token,
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data.status.error == false) {
    //         this.$router.push({
    //           path: "/viewOnlineExam/" + this.homeworkId,
    //         });
    //       }
    //     });
    // },

    openConfirmDialog(item, index, sectionIndex) {
      this.confirmDialog = true;
      this.deletedItem = item;
      this.selectedQuestionIndex = index;
      this.selectedSectionIndex = sectionIndex;
    },
    deleteItem(item) {
      if (item == "question" && confirm) {
        this.deleteQuestion(
          this.selectedQuestionIndex,
          this.selectedSectionIndex
        );
      } else if (item == "section" && confirm) {
        this.deleteSection(this.selectedSectionIndex);
      }
      this.confirmDialog = false;
    },
    checkAutoCorrection() {
      if (this.HomeWorkData.auto_correction == true) {
        this.types = this.types.filter((item) => {
          return item.text != "Essay";
        });

        this.HomeWorkSections[0].questions[0].type = "mcq";
        this.HomeWorkSections[0].questions[0].options = [
          {
            key: this.$i18n.t("Option"),
            value: "",
          },
        ];
      }
    },
  },
  computed: {
    // filteredItems() {
    // console.log(this.tag);
    // return this.autocompleteItems.filter((i) => {
    //   return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
    // });
    // },
  },
  mounted() {
    this.homeworkId = this.$router.currentRoute.params.homeworkId;
    this.getHomeWorkData();
    // this.getTags();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.exam-section {
  margin-bottom: 1rem;
}

.sectionInput {
  background-color: #eff3ff;
  padding: 25px 0px 25px 50px;
}
hr.hr-bold {
  border-top: 2px solid rgb(18 2 2 / 10%);
}

label {
  margin-top: 0 !important;
}
.clickable {
  cursor: pointer;
}
.questionbody {
  box-shadow: $box-shadow;
  padding-bottom: 2px !important;
  // margin-top: 2px;
  margin-bottom: 2px;
}
.addQuestion {
  padding: 10px;
  background-color: #eff3ff;
}

.addOption {
  padding: 5px;
  background-color: #eff3ff;
}
.tags {
}

.divider {
  height: 2rem;
  width: 100%;
  background-color: #eff3ff;
  margin: 0 !important;
  padding: 0 !important;
}

.displayDiv {
  display: block !important;
}
.hiddenDiv {
  display: none;
}
.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}
</style>
